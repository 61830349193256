.small-print-header {
  margin-bottom: 3rem;
  &__logo {
    margin: 0 0 3rem $gutter-half-width;
    path {
      fill: $gray-2;
      transition: fill 0.2s;
    }
    &:hover path {
      fill: $text-color;
    }
  }
  &__heading {
    @extend %gutters;
    @extend %text-column;
    @include h2-font;
    &:after {
      content: '';
      display: block;
      padding-top: 2rem;
      border-bottom: 1px solid $black-hairline;
    }
  }
  @include breakpoint($nav-breakpoint) {
    &__logo {
      margin-top: 0.5rem;
    }
  }
  @include breakpoint(large) {
    &__logo {
      float: left;
      margin-bottom: 0;
    }
    &__heading {
      padding-top: 1.5rem;
      &:after {
        padding-top: 1.5rem;
      }
    }
  }
}
