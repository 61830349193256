.account-links {
  margin-top: 6rem;
  border-bottom: 1px solid $black-hairline;
  &__link {
    display: flex;
    align-items: center;
    border-top: 1px solid $black-hairline;
    &__text {
      @include body-sans-serif-font;
      color: $gray-3;
      margin: 2.5rem 0 2.5rem 1.5rem;
      transition: color 0.15s;
    }
    &:hover &__text {
      color: $teal;
    }
  }
  @include breakpoint(smallish) {
    display: flex;
    border-bottom: none;
    &__link {
      border-top: none;
      flex-direction: column;
      flex-basis: 0;
      flex-grow: 1;
      &:not(:last-child) {
        border-right: 1px solid $black-hairline;
      }
      &__text {
        text-align: center;
        margin: 1rem 0 0;
        max-width: 16rem;
      }
    }
  }
  @include breakpoint(medium) {
    margin-top: 8rem;
  }
}
