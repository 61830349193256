$image-reveal-speed: 2s;

.home-hero {
  @include vh100-ios;
  transition: height 0.3s;
  .show-jw-cookies & {
    height: calc(var(--vh, 1vh) * 100 - 6rem);
  }
  overflow: hidden;
  position: relative;
  .JS & {
    visibility: hidden;
  }
  .JS &--ready {
    visibility: visible;
  }
  &__images {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(var(--heroParallax));
    will-change: transform;
    &__img {
      background-size: cover !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 2s;
      &:first-child {
        opacity: 1;
      }
      &--fading-in {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  &__image-vignette {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      farthest-side,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 50%,
      rgba(0, 0, 0, 0.3)
    );
  }
  &__image-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $home-hero-bg-color;
    // background-color: #111;
    transition: background-color $image-reveal-speed;
    .home-hero--image-revealed &,
    .home-hero--animation-skipped & {
      background-color: transparent;
    }
    .home-hero--animation-skipped & {
      transition: none;
    }
  }
  &__content {
    z-index: 1;
    position: relative;
    height: 100%;
  }
  &__tagline {
    @extend %gutters;
    @include hero-tagline;
    color: #fff;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: text-shadow $image-reveal-speed, opacity 1s;
    opacity: 0;
    .home-hero--tagline-revealed &,
    .home-hero--animation-skipped & {
      opacity: 1;
    }
    .home-hero--image-revealed & {
      text-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    }
    .home-hero--animation-skipped & {
      transition: none;
    }
    &__word {
      color: transparent;
      display: inline-block;
      position: relative;
      transition: color 0.15s;
      .home-hero--animation-skipped & {
        transition: none;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 2.4rem;
        left: 0;
        right: 0;
        height: 2.625rem;
        border-radius: 5px;
        background-color: #fff;
        transition: background-color 0.15s;
        .home-hero--animation-skipped & {
          transition: none;
        }
      }
      &--revealed,
      .home-hero--animation-skipped & {
        color: #fff;
        &:before {
          background-color: transparent;
        }
      }
    }
  }
  &__skip-to-content {
    position: absolute;
    left: 50%;
    bottom: 3rem;
    transform: translate(-50%, 0);
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
      transform: translate(-50%, 0.5rem);
    }
  }
}
