.message {
  background: #fff;
  border: 1px solid $off-off-white;
  border-radius: 8px;
  padding: 2.5rem;
  margin-bottom: 5rem;
  .auth & {
    background: #fff;
    text-align: center;
  }
  &--white {
    background: #fff;
  }
  &--no-top-margin {
    margin-top: 0; /* I'm sure there's a better way to do this */
  }
  &--centered {
    text-align: center;
  }
  &--standalone {
    margin: 10rem $screen-margin-small-with-gutter 4rem;
    @include breakpoint($nav-breakpoint) {
      width: 60rem;
      margin: 19rem auto 10rem;
    }
    .download-btns {
      margin: 3rem 0 1rem;
      justify-content: center;
    }
  }
  &__heading {
    @include h3-font;
    margin-bottom: 2rem;
    &--dark-teal {
      color: $dark-teal;
    }
    &__icon {
      margin-right: 1rem;
      display: inline-block;
      vertical-align: top;
      svg {
        height: 3.5rem;
        width: 3.5rem;
      }
    }
  }
  &__body {
    @include body-sans-serif-font;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    a {
      @include link();
    }
    strong {
      font-weight: 600;
    }
  }
  &__direct-download {
    display: flex;
    justify-content: center;
    margin: 3rem -1rem -1rem;
    flex-wrap: wrap;
    &__link {
      margin: 0 0.75rem 2rem;
    }
  }
  &--phone-img {
    position: relative;
    padding-right: 24rem;
    min-height: 24rem;
  }
  &__phone-img {
    position: absolute;
    bottom: 0;
    right: 3rem;
  }
}
