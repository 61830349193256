.owl-dots {
  text-align: center;
  line-height: 1rem;
  .owl-dot {
    display: inline-block;
    padding: 0.75rem;
    margin: -0.75rem 0; // cancels out space caused by vertical padding
    opacity: 0.6;
    &.active,
    &:hover {
      opacity: 1;
    }
    span {
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: #fff;
    }
  }
}

// show carousels that have been disabled - based on http://stackoverflow.com/questions/28251644/disabling-owl-carousel-at-a-specific-viewport-width
.owl-carousel.off {
  display: block;
}
