.mt0 {
  margin-top: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.ml0 {
  margin-left: 0 !important;
}
.mr0 {
  margin-right: 0 !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
}
.pr0 {
  padding-right: 0 !important;
}
.break-word {
  word-break: break-all;
}
