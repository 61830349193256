.e-coupon {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__lupa-logo {
      width: 5.375rem;
      height: 4rem;
      margin: 1rem 0 0 1rem;
    }
    &__label {
      @include body-sans-serif-font;
      color: $gray-2;
      margin: 0 0.5rem 0 0;
    }
  }
  &__price {
    @include h1-font;
    margin: 1.75rem 0 0rem;
    font-size: 5.5rem;
    line-height: 5.5rem;
    text-align: center;
    &__currency-symbol {
      @include h4-font;
      font-size: 3rem;
      line-height: 3rem;
      position: relative;
      bottom: 1.5rem;
      right: 1px;
    }
  }
  &__price-description {
    @include body-sans-serif-font;
    font-weight: 400;
    text-align: center;
    margin-bottom: 3.75rem;
  }
  .btn {
    display: block;
  }
  @include breakpoint(384px) {
    max-width: 320px;
  }
}
