.contact {
  height: 100%;
  background: $off-white;
  padding-top: 6rem + $header-height-small;
  padding-bottom: 8rem;
  &__content {
    @extend %section-wrap;
  }
  &__heading {
    @extend %gutters;
    @include h1-font;
    margin-bottom: 7rem;
  }
  &__column {
    @extend %gutters;
    &:not(:last-child) {
      margin-bottom: 6rem;
    }
    .btn {
      margin-top: 1rem;
    }
  }
  &__icon {
    margin-bottom: 1rem;
  }
  &__subheading {
    @include h2-font;
    margin-bottom: 2rem;
  }
  &__p {
    margin-bottom: 2rem;
  }
  @include breakpoint(medium) {
    padding-top: 10rem + $header-height-medium;
    padding-bottom: 14rem;
    &__content {
      display: flex;
      flex-wrap: wrap;
    }
    &__heading {
      width: 100%;
      margin-bottom: 10rem;
    }
    &__column {
      width: percentage(1/2);
    }
  }
  @include breakpoint(1100px) {
    &__column--3 {
      width: percentage(1/3);
    }
  }
}
