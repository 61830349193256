.download-cta {
  padding: 0 $screen-margin-small-with-gutter 0;
  background: $off-off-white;
  text-align: center;
  &__heading {
    @include h2-font;
    margin-bottom: 2rem;
    .download-cta--compact & {
      @include h3-font;
    }
  }
  .download-btns {
    justify-content: center;
  }
  @include breakpoint(smallish) {
    &__heading,
    &__text {
      max-width: 48rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
