.coupons-header {
  padding-top: 6rem + $header-height-small;
  background: $dark-blue;
  overflow: hidden;
  * {
    color: #fff;
  }
  p a {
    @include white-link;
  }
  &__content {
    @extend %section-wrap;
  }
  &__text {
    @extend %gutters;
    padding-bottom: 6rem;
    max-width: 650px;
    margin: 0 auto;
  }
  &__heading {
    @include h1-font;
    color: $yellow;
    margin-bottom: 3rem;
  }
  &__intro {
    margin-bottom: 4rem;
  }
  &__tier {
    margin-bottom: 3rem;
    &__icon {
      float: left;
      width: 5rem;
      height: 5rem;
      margin-top: 0.5rem;
      path {
        fill: $yellow;
      }
    }
    &__summary {
      margin-left: 6.5rem;
      @include h4-font;
    }
    &__students {
      margin-left: 6.5rem;
      @include body-sans-serif-font;
      opacity: 0.7;
    }
  }
  &__cta {
    margin: 4.5rem 0 4rem;
    @include body-font;
    line-height: 6rem;
    @include h4-font;
    .btn {
      margin: 0;
      @include below-breakpoint(450px) {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
      }
    }
    &__why {
      line-height: 6rem;
      opacity: 0.5;
      @include link(#fff);
    }
  }
  &__footnote {
    @include body-sans-serif-font;
    opacity: 0.7;
  }
  &__img {
    margin: 0 auto;
  }
  @include breakpoint(smallish) {
    &__btns {
      .btn {
        display: inline-block;
      }
    }
  }
  @include breakpoint(medium) {
    padding-top: 10rem + $header-height-medium;
  }
  @include breakpoint(920px) {
    &__content {
      display: flex;
      justify-content: space-between;
    }
    &__text {
      text-align: left;
      margin: 0;
      padding-bottom: 7rem;
    }
    &__img {
      display: block;
      margin: 0 $gutter-half-width 0 3rem;
      align-self: flex-end;
      transform: translateY(var(--appPromoParallax));
      will-change: transform;
    }
    &__cta {
      margin-bottom: 4rem;
    }
  }
  @include breakpoint(huge) {
    &__text {
      width: 50%;
      max-width: 600px;
    }
    &__img {
      justify-self: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
