.account-table {
  width: 100%;
  table-layout: fixed;
  &__tr {
    &:not(.account-table__tr--no-rule) {
      border-top: 1px solid $black-hairline;
    }
    &:last-child {
      border-bottom: 1px solid $black-hairline;
    }
  }
  &__td {
    @include body-sans-serif-font;
    padding: 2.5rem 0;
    vertical-align: top;
    position: relative;
    &--label {
      color: $gray-3;
      width: percentage(1/3);
      padding-right: 1rem;
    }
    &--value {
      width: percentage(2/3);
    }
    &--error {
      color: $error-color;
      padding-left: 3.5rem;
      background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20fill%3D%22%23FF6767%22%20fill-rule%3D%22evenodd%22%20d%3D%22M10%2020C4.477%2020%200%2015.523%200%2010S4.477%200%2010%200s10%204.477%2010%2010-4.477%2010-10%2010zM9.99%203c-.804%200-1.431.385-1.431%201.068%200%202.084.274%205.078.274%207.162%200%20.542.53.77%201.157.77.47%200%201.137-.228%201.137-.77%200-2.084.275-5.078.275-7.162C11.402%203.385%2010.755%203%209.99%203zm.02%2013.5c.804%200%201.49-.701%201.49-1.5%200-.818-.686-1.5-1.49-1.5-.863%200-1.51.682-1.51%201.5%200%20.799.647%201.5%201.51%201.5z%22%2F%3E%3C%2Fsvg%3E)
        0 2.75rem no-repeat;
    }
  }
  &__link {
    @include link;
    float: right;
    margin-left: 1rem;
    transition: opacity 0.3s, visibility 0.3s;
    .account-table__editable--updated & {
      opacity: 0;
      visibility: hidden;
      transition: none;
    }
  }
  &__note {
    background: $off-white;
    border: 1px solid $off-off-white;
    border-radius: 8px;
    padding: 2rem;
    margin-top: 2.5rem;
    &__p {
      @include body-sans-serif-font;
      color: $gray-3;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      strong {
        font-weight: 400;
        color: $black;
      }
    }
    &__link {
      @include body-sans-serif-font;
      border: none;
      outline: none;
      padding: 0;
      background: none;
      @include link();
      margin-right: 2rem;
    }
  }
  &__update-success {
    position: absolute;
    top: 2rem;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    .account-table__editable--updated & {
      opacity: 1;
      visibility: visible;
      transition: none;
    }
  }
  &__edit {
    margin: -1.5rem 0 -0.5rem;
    .textInput {
      margin-bottom: 1rem;
    }
    .btn {
      margin: 1rem 0 0;
      float: left;
    }
    &__btn-extra-text {
      display: none;
      @include breakpoint(smallish) {
        display: inline;
      }
    }
    &__cancel {
      @include body-sans-serif-font;
      background: none;
      padding: 0;
      margin: 2rem 0 0 2rem;
      border: none;
      @include link($gray-3);
      cursor: pointer;
      outline: none;
    }
    &__footnote {
      @include small-text-font;
      color: $gray-3;
      margin-top: 3rem;
    }
  }
  .pricing-card-list {
    margin-top: 3rem;
  }
}
