.hero-header {
  background: $nav-bg-color;
  padding-top: 6rem + $header-height-small;
  padding-bottom: 7rem;
  position: relative;
  overflow: hidden;
  &__title {
    @extend %gutters;
    @include h1-font;
    color: #fff;
    z-index: 1;
    position: relative;
  }
  &__standfirst {
    @extend %gutters;
    margin-top: 4rem;
    z-index: 1;
    position: relative;
    max-width: 594px;
    &--indented {
      max-width: $text-max-width;
      @extend %text-column;
    }
    p {
      @include body-font;
      color: #fff;
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover !important;
    transform: translateY(var(--heroParallax));
    will-change: transform;
  }
  @include breakpoint(medium) {
    padding-top: 10rem + $header-height-medium;
    padding-bottom: 10rem;
    &__standfirst {
      margin-top: 6rem;
      &--indented {
        margin-top: 10rem;
      }
    }
  }
  @include breakpoint(large) {
    &__title {
      width: percentage(8/12);
    }
  }
  @include breakpoint(huge) {
    &__title {
      width: percentage(7/12);
    }
  }
}
