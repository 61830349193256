$input-border-color: darken($off-off-white, 5%);

%label {
  display: block;
  margin-bottom: 1rem;
  @include body-sans-serif-font;
}
%textInput {
  display: block;
  border: 1px solid $input-border-color;
  width: 100%;
  padding: calc(1.5rem - 1px) 2rem;
  margin-bottom: 3rem;
  @include body-sans-serif-font;
  border-radius: 4px;
  @include breakpoint(smallish) {
    margin-bottom: 1rem;
  }
}
%textarea {
  display: block;
  border: 1px solid $input-border-color;
  width: 100%;
  padding: calc(1.5rem - 1px) 2rem;
  height: 20rem;
  @include body-sans-serif-font;
  border-radius: 4px;
  margin-bottom: 3rem;
  @include breakpoint(smallish) {
    margin-bottom: 1rem;
  }
}
%select {
  display: inline-block;
  padding: 1rem 6rem 1rem 1.5rem;
  border: 1px solid $input-border-color;
  @include body-font;
  color: $text-color;
  margin-bottom: 3rem;
  background: #fff
    url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ0IDQ4Ij48cGF0aCBmaWxsPSIjNDU0NTQ1IiBkPSJNMTUgMTloMTRsLTcgMTF6Ii8+PC9zdmc+)
    top right no-repeat;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ''; // removes arrow from FF - see http://css-tricks.com/almanac/properties/a/appearance/
  // remove select arrows on IE
  &::-ms-expand {
    display: none;
  }
}
.form {
  &__field {
    position: relative;
  }
  &__error {
    @include body-sans-serif-font;
    color: $error-color;
    padding-left: 3.25rem;
    background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20fill%3D%22%23FF6767%22%20fill-rule%3D%22evenodd%22%20d%3D%22M10%2020C4.477%2020%200%2015.523%200%2010S4.477%200%2010%200s10%204.477%2010%2010-4.477%2010-10%2010zM9.99%203c-.804%200-1.431.385-1.431%201.068%200%202.084.274%205.078.274%207.162%200%20.542.53.77%201.157.77.47%200%201.137-.228%201.137-.77%200-2.084.275-5.078.275-7.162C11.402%203.385%2010.755%203%209.99%203zm.02%2013.5c.804%200%201.49-.701%201.49-1.5%200-.818-.686-1.5-1.49-1.5-.863%200-1.51.682-1.51%201.5%200%20.799.647%201.5%201.51%201.5z%22%2F%3E%3C%2Fsvg%3E)
      0 0.25rem no-repeat;
    &:after {
      content: '';
      display: block;
      height: 3rem;
    }
    max-height: 9rem;
    transition: all 0.3s;
    &--hidden {
      max-height: 0;
      opacity: 0;
      &:after {
        display: none;
      }
    }
  }
  &__errors {
    margin: -2rem 0 3rem;
    &__error {
      @include body-sans-serif-font;
      font-weight: 600;
      color: $error-color;
    }
    &__alert-icon {
      float: left;
      margin: 0.25rem 0.75rem 0 0;
      path {
        fill: $error-color;
      }
    }
  }
  .btn {
    min-width: 30rem;
    display: block;
  }
}
.label {
  @extend %label;
  &--required:after {
    content: '*';
  }
}
.textInput {
  @extend %textInput;
  &--no-border {
    border: none;
  }
  &--password {
    padding-right: 6rem;
  }
  &--error {
    border-color: lighten($error-color, 15%);
    background: lighten($error-color, 34%);
  }
}
.textarea {
  @extend %textarea;
}
.select {
  @extend %select;
}
.show-password {
  width: 6.5rem;
  height: 6rem;
  border: none;
  text-indent: -999em;
  padding: 0;
  background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2252%22%20height%3D%2248%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20fill%3D%22%23999%22%20fill-rule%3D%22nonzero%22%20d%3D%22M15.287%2029.592c-1.887-1.243-3.602-2.927-5.084-4.96a1.09%201.09%200%200%201%200-1.269C13.528%2018.806%2018.033%2016%2023%2016c1.707%200%203.359.331%204.927.951l-2.172%202.173a5.32%205.32%200%200%200-3.111-.757c-2.723.175-4.93%202.454-5.1%205.265a5.787%205.787%200%200%200%20.647%203.055l-2.904%202.905zm2.786%201.457l2.172-2.173a5.32%205.32%200%200%200%203.111.757c2.718-.18%204.925-2.46%205.1-5.265a5.787%205.787%200%200%200-.647-3.055l2.904-2.905c1.887%201.243%203.602%202.927%205.084%204.96a1.09%201.09%200%200%201%200%201.269C32.472%2029.194%2027.967%2032%2023%2032a13.34%2013.34%200%200%201-4.927-.951zm4.125-4.126l3.664-3.664c.071.296.1.61.08.933-.097%201.521-1.288%202.746-2.75%202.839a2.876%202.876%200%200%201-.994-.108zm-2.055-2.187a3.14%203.14%200%200%201-.079-.934c.09-1.515%201.282-2.74%202.75-2.838.346-.023.68.016.993.108l-3.664%203.664z%22%2F%3E%3Cpath%20stroke%3D%22%23999%22%20d%3D%22M33%2014L13%2034%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E);
  position: absolute;
  top: 4rem;
  right: 0;
  @include breakpoint(smallish) {
    top: 0;
  }
  .account-table & {
    top: 0;
  }
}
.show-password:active {
  outline: none;
}
.show-password--active {
  background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2252%22%20height%3D%2248%22%3E%3Cpath%20fill%3D%22%23999%22%20d%3D%22M23%2016c-4.968%200-9.472%202.806-12.797%207.363a1.09%201.09%200%200%200%200%201.268C13.528%2029.194%2018.033%2032%2023%2032c4.968%200%209.472-2.806%2012.797-7.363a1.09%201.09%200%200%200%200-1.268C32.472%2018.806%2027.967%2016%2023%2016zm.356%2013.633c-3.297.215-6.02-2.591-5.813-6%20.17-2.812%202.378-5.09%205.1-5.266%203.298-.215%206.021%202.591%205.814%206-.176%202.807-2.383%205.085-5.1%205.266zm-.165-2.602c-1.776.115-3.244-1.395-3.127-3.229.09-1.515%201.282-2.74%202.75-2.838%201.776-.116%203.244%201.394%203.127%203.228-.096%201.521-1.287%202.746-2.75%202.839z%22%2F%3E%3C%2Fsvg%3E);
}
// move labels inside fields
@include breakpoint(smallish) {
  .label {
    position: absolute;
    top: 1.625rem;
    left: 2rem;
    color: $gray-3;
    width: 15rem;
  }
  .textInput:not(.textInput--no-label),
  .textarea:not(.textarea--no-label) {
    padding-left: 17rem;
  }
  .btn {
    margin-top: 3rem;
  }
}
