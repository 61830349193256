.how-it-works {
  @extend %section-wrap;
  &__standfirst {
    @extend %gutters;
    @include standfirst-font;
    margin-bottom: 5rem;
  }
  &__heading {
    @extend %gutters;
    @include h2-font;
    &:after {
      content: '';
      display: block;
      width: 10rem;
      height: 1.25rem;
      background: $gray-1;
      border-radius: 3px;
      margin: 2rem 0 2.5rem;
    }
  }
  &__intro {
    @extend %gutters;
  }
  &__desktop-screenshot {
    display: none;
  }
  &__learn-more-heading {
    @extend %gutters;
    @include h2-font;
    margin: 5rem 0 3rem;
  }
  &__learn-more {
    @extend %gutters;
  }
  @include breakpoint(smallish) {
    &__standfirst,
    &__heading,
    &__intro,
    &__learn-more-heading,
    &__learn-more {
      text-align: center;
      max-width: 580px;
      margin-left: auto;
      margin-right: auto;
    }
    &__heading:after {
      margin: 2rem auto 2.5rem;
    }
  }
  @include breakpoint(medium) {
    &__standfirst {
      margin-bottom: 10rem;
    }
  }
  @include breakpoint(875px) {
    .screenshot-carousel {
      margin-bottom: 12rem;
    }
    // 	.screenshot-carousel {
    // 		display: none;
    // 	}
    // 	&__desktop-screenshot {
    // 		display: block;
    // 		padding: 0 $gutter-half-width;
    // 		margin: 8rem auto 14rem;
    // 	}
  }
}
