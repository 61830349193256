.modal {
  background: $off-off-white;
  border-radius: 1rem;
  max-width: 360px;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 2rem 2rem 0;
  position: relative;
  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    text-indent: -999em;
    border: none;
    background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-linecap%3D%22round%22%20stroke-opacity%3D%22.4%22%20stroke-width%3D%222%22%20d%3D%22M16%2016L8.507%208.507%2016%2016l7.493-7.493L16%2016zm0%200l-7.493%207.493L16%2016l7.493%207.493L16%2016z%22%2F%3E%3C%2Fsvg%3E)
      center center;
    cursor: pointer;
  }
  &__icon {
    margin: 1rem auto;
    width: 6rem;
    height: 6rem;
  }
  &__heading {
    @include h4-font;
    margin-bottom: 2rem;
    &--centered {
      text-align: center;
    }
    &--small {
      @include body-sans-serif-font;
      font-weight: 400;
    }
    &__icon {
      display: inline-block;
      width: 3rem;
      height: 3rem;
      vertical-align: top;
      margin-right: 0.5rem;
    }
  }
  &__body {
    @include body-sans-serif-font;
    margin-bottom: 2rem;
  }
  &__email-check {
    text-align: center;
    @include h4-font;
    font-weight: 600;
    color: $black;
    margin: 3rem 0 4rem;
    word-break: break-all;
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $black-hairline;
    &__btn {
      @include body-sans-serif-font;
      font-weight: 400;
      &:only-child {
        margin: 0 auto;
      }
    }
    &__link {
      display: inline-block;
      padding: 2rem;
      color: $teal;
      transition: color 0.1s;
      margin: 0 -2rem;
      &:hover {
        color: $darker-teal;
      }
      &--cancel {
        color: $gray-3;
        &:hover {
          color: darken($gray-3, 10%);
        }
      }
      &--danger {
        color: #e55c58;
        &:hover {
          color: darken(#e55c58, 10%);
        }
      }
    }
  }
  .btn {
    width: 100%;
    margin: 0 0 2rem;
  }
  &__error {
    @include body-sans-serif-font;
    font-weight: 600;
    text-align: left;
    margin: -1rem 0 2rem;
    color: $error-color;
  }
}
