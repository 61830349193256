.modal-container {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  &.closed {
    display: none;
  }
}
.account {
  @extend %screen-margins-with-gutters;
  padding-top: 5rem + $header-height-small;
  padding-bottom: 7rem;
  &--loaded {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.25s;
  }
  &--loading {
    opacity: 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 7rem;
    &__heading {
      @include h1-font;
      padding-right: 2rem;
    }
    .btn {
      margin-top: 0.75rem;
    }
  }
  &__subheading {
    @include h3-font;
    margin: 5rem 0 2rem;
  }
  &__member-since {
    @include body-sans-serif-font;
    padding: 2.5rem 0;
    color: $gray-3;
  }
  &__subscribe-prompt {
    @include body-sans-serif-font;
    padding: 2.5rem 0;
    border-top: 1px solid $black-hairline;
    margin-bottom: 2rem;
    &--no-rule {
      border-top: none;
    }
  }
  &__promo-link {
    @include body-sans-serif-font;
    margin-top: 5rem;
    text-align: center;
    a {
      @include link;
    }
  }

  &__close-account-container {
    text-align: right;
    padding-top: 2rem;
  }

  &__close-account-link {
    @include body-sans-serif-font;
    color: #e55c58;
    text-decoration: underline;
  }

  @include breakpoint(medium) {
    width: 80rem;
    margin: 0 auto;
    padding-top: 8rem + $header-height-medium;
    padding-bottom: 10rem;
    &__header {
      margin-bottom: 10rem;
      .btn {
        margin-top: 1.5rem;
      }
    }
    &__subheading {
      margin-top: 7rem;
    }
  }
}

.hide {
  display: none;
}
