.close-account {
  @extend %screen-margins-with-gutters;
  padding-top: 6rem + $header-height-small;
  padding-bottom: 10rem;
  &__heading {
    @include h3-font;
    margin-bottom: 2rem;
  }
  &__copy {
    @include body-sans-serif-font;
    margin-bottom: 4rem;
  }
  &__btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .message__heading__icon {
    margin-right: 1rem;
  }
  .btn {
    margin: 0;
  }
  @include breakpoint(medium) {
    width: 80rem;
    margin: 0 auto;
    padding-top: 10rem + $header-height-medium;
    padding-bottom: 14rem;
  }
}

.hide {
  display: none;
}
