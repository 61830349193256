.feature-list {
  .how-it-works__intro + & {
    margin-top: 6rem;
  }
  &__item {
    @extend %gutters;
    display: flex;
    margin-bottom: 4rem;
  }
  &__icon {
    width: 6rem;
    height: 6rem;
    flex-shrink: 0;
    margin-top: 1rem;
  }
  &__text {
    padding-left: 2rem;
    max-width: 400px;
  }
  &__heading {
    @include body-font;
    margin-bottom: 1.5rem;
  }
  p {
    @include body-sans-serif-font;
  }
  @include breakpoint(smallish) {
    .how-it-works__intro + & {
      margin-top: 8rem;
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      flex-direction: column;
      width: percentage(1/2);
      margin-bottom: 6rem;
      opacity: var(--featureListAnimation);
      transform: translateY(calc(50px - (var(--featureListAnimation) * 50px)));
    }
    &__icon {
      width: 7rem;
      height: 7rem;
      margin: 0 0 2rem;
    }
    &__text {
      padding-left: 0;
      max-width: 330px;
    }
  }
  @include breakpoint(900px) {
    .how-it-works__intro + & {
      margin-top: 10rem;
    }
    &__item {
      flex-direction: column;
      width: percentage(1/3);
    }
  }
}
