.story-card {
  @extend %gutters;
  margin-bottom: 2rem;
  width: 100%;
  cursor: default;
  &:before {
    content: '';
    display: block;
    border-top: 1px solid $black-hairline;
    margin-bottom: 2rem;
  }
  &__img {
    width: 12.5rem;
    height: 12.5rem;
    float: left;
    position: relative;
    outline: 1px solid rgba(0, 0, 0, 0.06);
    outline-offset: -1px;
  }
  &__text {
    margin: -0.5rem 0 0 14.5rem;
  }
  &__heading {
    @include body-sans-serif-font;
    font-weight: 600;
    margin-bottom: 0.5rem;
    &__part {
      color: $gray-3;
    }
  }
  &__meta {
    @include body-sans-serif-font;
    color: $gray-3;
    margin-bottom: 0.5rem;
  }
  &__tagline {
    @include body-sans-serif-font;
  }
  @include breakpoint(800px) {
    width: 50%;
    margin-bottom: 2rem;
  }
  @include breakpoint(1100px) {
    width: percentage(1/3);
  }
}
