* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

%section-wrap {
  @extend %screen-margins;
  max-width: $max-screen-width;
  margin: 0 auto;
}

.section-wrap {
  @extend %section-wrap;
}

// sticky footer to work in IE11 (https://philipwalton.com/articles/normalizing-cross-browser-flexbox-bugs/)
body {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
.site-header,
.site-footer {
  flex-shrink: 0;
}

/******************************************************************************************
GRID
******************************************************************************************/

%gutters {
  padding-left: $gutter-half-width;
  padding-right: $gutter-half-width;
}
%left-gutter {
  padding-left: $gutter-half-width;
}
%right-gutter {
  padding-right: $gutter-half-width;
}
%negative-gutters {
  margin-left: -$gutter-half-width;
  margin-right: -$gutter-half-width;
}
%screen-margins {
  padding-left: $screen-margin-small;
  padding-right: $screen-margin-small;
  @include breakpoint(medium) {
    padding-left: $screen-margin-medium;
    padding-right: $screen-margin-medium;
  }
}
%screen-margins-with-gutters {
  padding-left: $screen-margin-small-with-gutter;
  padding-right: $screen-margin-small-with-gutter;
  @include breakpoint(medium) {
    padding-left: $screen-margin-medium-with-gutter;
    padding-right: $screen-margin-medium-with-gutter;
  }
}
%negative-screen-margins {
  margin-left: -$screen-margin-small;
  margin-right: -$screen-margin-small;
  @include breakpoint(medium) {
    margin-left: -$screen-margin-medium;
    margin-right: -$screen-margin-medium;
  }
}
%negative-screen-with-gutters {
  margin-left: -$screen-margin-small-with-gutter;
  margin-right: -$screen-margin-small-with-gutter;
  @include breakpoint(medium) {
    margin-left: -$screen-margin-medium-with-gutter;
    margin-right: -$screen-margin-medium-with-gutter;
  }
}

/******************************************************************************************
TEXT/IMAGE COLUMNS
******************************************************************************************/

%text-column {
  max-width: $text-max-width;
  @include breakpoint(large) {
    width: percentage(7/12);
    margin-left: percentage(4/12);
  }
}

%8-column {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint(large) {
    width: percentage(8/12);
  }
}

%section-margins {
  padding-top: 5rem;
  padding-bottom: 7rem;
  @include breakpoint(smallish) {
    padding-top: 6rem;
    padding-bottom: 8rem;
  }
  @include breakpoint(medium) {
    padding-top: 8rem;
    padding-bottom: 10rem;
  }
}
