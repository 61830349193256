.icon-list {
  &__item {
    display: flex;
    margin-bottom: 3rem;
    padding-left: 0 !important;
    &:before {
      content: none !important;
    }
  }
  &__icon {
    width: 6rem;
    flex-shrink: 0;
    margin-right: 2rem;
  }
}
