.pricing-card-list {
  &--default {
    padding: 0 $gutter-half-width;
    @include breakpoint($pricing-card-breakpoint) {
      display: flex;
      justify-content: space-between;
      padding: 0 $gutter-half-width - 8px;
      max-width: ($pricing-card-width * 3) + 12rem;
      margin: 0 auto;
    }
  }
  &--account {
    @include breakpoint($account-pricing-card-breakpoint) {
      display: flex;
      justify-content: space-between;
    }
  }
}
