.article-list {
  margin-bottom: -5rem;
  display: flex;
  flex-wrap: wrap;
  &__heading {
    @extend %gutters;
    @include h2-font;
    margin-bottom: 4rem;
    width: 100%;
  }
}
