.story-feature-list {
  display: flex;
  flex-wrap: wrap;
  &__teaser {
    @extend %gutters;
    @include body-sans-serif-font;
    font-style: italic;
    color: $gray-3;
    margin-bottom: 2rem;
    @include breakpoint(medium) {
      margin-bottom: 5rem;
      .story-feature--single + & {
        margin-top: -5rem;
        margin-bottom: 8rem;
      }
    }
  }
}
