.caption {
  margin: 1rem 0 -3rem;
  @include small-text-font;
  text-align: right;
  color: $gray-3;
  &--left {
    text-align: left;
    margin-bottom: -2rem;
  }
}
