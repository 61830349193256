.story-feature {
  margin-bottom: 5rem;
  &__img-wrap {
    @extend %gutters;
    margin-bottom: 2rem;
  }
  &__img {
    outline: 1px solid rgba(0, 0, 0, 0.06);
    outline-offset: -1px;
  }
  &__text {
    @extend %gutters;
  }
  &__new-this-week {
    @include h3-font;
    color: $gray-3;
  }
  &__heading {
    @include h2-font;
    &__part {
      color: $gray-3;
    }
  }
  &__meta {
    @include body-sans-serif-font;
    color: $gray-3;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
  &__tagline {
    @include body-sans-serif-font;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  &__description {
    @include body-sans-serif-font;
  }
  @include breakpoint(medium) {
    width: 50%;
    margin-bottom: 6rem;
    &--single {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 0;
      .story-feature__img-wrap {
        width: 50%;
      }
      .story-feature__text {
        width: 50%;
        padding-bottom: 9rem;
      }
    }
  }
  @include breakpoint(huge) {
    &--single {
      .story-feature__text {
        width: percentage(5/12);
      }
    }
  }
}
