.notification {
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 30rem;

  &__icon {
    margin-right: 1rem;
  }

  &__message {
    @include body-sans-serif-font;
  }
}
