$hamburger-btn-width: 32px;
$hamburger-btn-height: 28px;
$hamburger-btn-line-width: 6px;
$hamburger-btn-padding-v: 8px;
$hamburger-btn-padding-h: 8px;

// body {
// 	padding-top: $header-height-small;
// 	@include breakpoint($nav-breakpoint) {
// 		padding-top: $header-height-medium;
// 	}
// }

.site-header {
  /******************************************************************************************
	Sticky header
	*******************************************************************************************/

  z-index: 1000;
  position: absolute;
  left: 0;
  right: 0;
  top: $header-height-small;
  margin-top: -$header-height-small; // slightly odd combo of top and margin-top here is so that transition kicks in right way when hidden/fixed
  @include breakpoint(medium) {
    top: $header-height-medium;
    margin-top: -$header-height-medium;
  }
  &--is-hidden,
  &--is-fixed {
    position: fixed;
    top: 0 !important;
    bottom: auto !important;
    left: 0;
    right: 0;
    transition: margin 0.3s;
  }
  &--is-hidden {
    margin-top: -$header-height-small;
    @include breakpoint(smallish) {
      margin-top: -$header-height-medium;
    }
  }
  &--is-fixed {
    margin-top: 0;
    box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.1);
  }

  /******************************************************************************************
	Mobile nav
	*******************************************************************************************/

  min-height: 8rem;
  transition: background-color 0.4s;
  &--nav-is-open,
  &--is-hidden,
  &--is-fixed {
    background-color: $nav-bg-color;
  }
  &--nav-is-open.site-header--white,
  &--is-hidden.site-header--white,
  &--is-fixed.site-header--white {
    background-color: #fff;
  }
  &--nav-is-open.site-header--off-white,
  &--is-hidden.site-header--off-white,
  &--is-fixed.site-header--off-white {
    background-color: $off-white;
  }
  &__content {
    @extend %section-wrap;
    position: relative;
  }
  &__logo-nav-btn {
    @extend %gutters;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
    position: relative;
  }
  &__logo {
    display: flex;
    margin-right: auto;
    path {
      fill: #fff;
      .site-header--white &,
      .site-header--off-white & {
        fill: $black;
      }
    }
    &__my {
      @include h4-font;
      line-height: 8rem;
      color: $black;
      margin-right: 0.5rem;
      position: relative;
      top: -0.25rem;
    }
    &__lupa {
      display: none;
    }
    &__link {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  // hamburger menu

  &__nav-btn {
    cursor: pointer;
    background: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'); // this is a transparent gif – makes whole nav btn clickable in IE9, rather than just the lines
    width: $hamburger-btn-width + ($hamburger-btn-padding-h * 2);
    height: $hamburger-btn-height + ($hamburger-btn-padding-v * 2);
    margin-right: -$hamburger-btn-padding-h;
    &__text {
      display: none;
    }
    &__lines {
      padding: $hamburger-btn-padding-v $hamburger-btn-padding-h;
      position: relative;
    }
    &__l {
      width: $hamburger-btn-width;
      height: $hamburger-btn-line-width;
      border-radius: 2px;
      background: #fff;
      position: absolute;
      left: $hamburger-btn-padding-h;
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      &--1 {
        top: $hamburger-btn-padding-v;
      }
      &--2,
      &--3 {
        top: ($hamburger-btn-height / 2) - ($hamburger-btn-line-width / 2) +
          $hamburger-btn-padding-v;
      }
      &--4 {
        top: $hamburger-btn-height - $hamburger-btn-line-width +
          $hamburger-btn-padding-v;
      }
      .site-header--white &,
      .site-header--off-white & {
        background: $black;
      }
    }
    .site-header--nav-is-open & {
      // turn hamburger into a cross
      .site-header__nav-btn__l--1 {
        top: $hamburger-btn-height / 2 - ($hamburger-btn-line-width / 2);
        width: 0%;
        left: 50%;
      }
      .site-header__nav-btn__l--2 {
        transform: rotate(45deg);
      }
      .site-header__nav-btn__l--3 {
        transform: rotate(-45deg);
      }
      .site-header__nav-btn__l--4 {
        top: $hamburger-btn-height / 2 - ($hamburger-btn-line-width / 2);
        width: 0%;
        left: 50%;
      }
    }
  }
  // Nav
  &__nav {
    clear: both;
    background-color: $nav-bg-color;
    .site-header--white & {
      background-color: #fff;
    }
    .site-header--off-white & {
      background-color: $off-white;
    }
    &__item {
      @include h4-font;
      padding-left: $screen-margin-small-with-gutter;
      padding-right: $screen-margin-small-with-gutter;
      line-height: 7rem;
      @include breakpoint(medium) {
        padding-left: $screen-margin-medium-with-gutter;
        padding-right: $screen-margin-medium-with-gutter;
      }
      &.is-standalone {
        &.is-logged-in {
          display: none;
        }

        &.is-logged-out {
          z-index: 5;
          position: absolute;
          right: $screen-margin-small;
          top: 0;
          @include breakpoint(medium) {
            right: $screen-margin-medium;
          }
          .site-header__nav__link {
            border-top: none;
          }
        }
      }
    }
    &__link {
      display: block;
      color: #fff;
      border-top: 1px solid $white-hairline;
      display: flex;
      svg {
        position: relative;
        top: 2rem;
        margin-right: 6px;
      }

      &--to-marketing {
        svg {
          top: 2.34rem;
          margin-right: 0;
          margin-left: 6px;
        }
      }

      .site-header--white &,
      .site-header--off-white & {
        color: $black;
        border-top: 1px solid $black-hairline;
      }
      &--home {
        border-top: none;
      }
      &--selected {
        color: $yellow;
        .site-header--white & {
          color: $teal;
          path {
            fill: $teal;
          }
        }
      }
    }
    &__icon {
      float: left;
      margin: 2rem 1rem 0 0;
      path {
        fill: #fff;
        .site-header--white &,
        .site-header--off-white & {
          fill: $black;
        }
      }
    }
    &__new {
      @include h4-font;
      font-size: 1.5rem;
      line-height: 2.25rem;
      color: rgba(0, 0, 0, 0.6);
      background: $yellow;
      padding: 0 0.375rem;
      border-radius: 0.25rem;
      margin-left: 0.25rem;
      position: relative;
      top: -0.125rem;
    }
    .JS & {
      position: absolute;
      bottom: $header-height-small;
      left: 0;
      right: 0;
      transition: transform 0.4s;
      &__item {
        opacity: 0;
        transition: opacity 0.4s;
      }
    }
    .JS .site-header--nav-is-open & {
      transform: translateY(calc(100% + #{$header-height-small}));
      box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
      &__item {
        opacity: 1;
      }
    }
  }

  /******************************************************************************************
	DESKTOP NAV
	*******************************************************************************************/

  @include breakpoint($nav-breakpoint) {
    &--is-hidden,
    &--is-fixed {
      background-color: rgba($color: $nav-bg-color, $alpha: 0.92);
      // backdrop-filter: url(commonfilters.svg#filter) blur(4px);
    }
    height: 9rem;
    &__content {
      display: flex;
      justify-content: flex-end;
    }
    &__nav-btn {
      display: none;
    }
    &__logo-nav-btn {
      margin-right: auto;
    }
    &__nav {
      position: relative !important;
      bottom: 0 !important;
      transform: none;
      display: flex;
      // flex: 1;
      justify-content: flex-end;
      background-color: transparent;
      .site-header--white &,
      .site-header--off-white & {
        background-color: transparent;
      }
      .JS & {
        display: flex;
        max-height: none;
      }
      &__item {
        line-height: 9rem;
        padding: 0;
        margin-right: 3rem;
        opacity: 1 !important;
        &:first-child {
          display: none;
        }
        &.is-nested {
          display: none;
        }
        &.is-standalone {
          margin-right: 0;
          display: block;
          padding-right: $gutter-half-width;
          &.is-logged-in {
            display: block;
          }
        }
      }
      &__link {
        border-top: none !important;
        position: relative;

        svg {
          top: 3rem;
        }
        &--to-marketing {
          svg {
            top: 3.2rem;
          }
        }

        &--selected {
          color: #fff;
          .site-header--white &,
          .site-header--off-white & {
            color: $black;
            path {
              fill: $black;
            }
          }
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0.75rem;
          left: 0;
          right: 0;
          height: 1.25rem;
          background: rgba(255, 255, 255, 0);
          border-radius: 3px;
          transition: background-color 0.2s;
          .site-header__nav__item--new & {
            right: 4.75rem;
          }
        }
        &--selected:after {
          background: rgba(255, 255, 255, 0.5);
          .site-header--white &,
          .site-header--off-white & {
            background: $teal;
          }
        }
        &:hover:after {
          background: rgba(255, 255, 255, 1);
          .site-header--white &,
          .site-header--off-white & {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
      &__icon {
        margin-top: 3rem;
      }
    }
  }
  @include breakpoint(medium) {
    &--home &__logo--lupa .site-header__logo__svg {
      transition: all 0.1s;
      width: 11rem;
      height: 11rem;
      margin-top: 1rem;
    }
    &--is-fixed &__logo--lupa .site-header__logo__svg,
    &--is-hidden &__logo--lupa .site-header__logo__svg {
      width: 8rem;
      height: 8rem;
      margin-top: 0;
    }
  }
  @include breakpoint(960px) {
    &__content {
      justify-content: center;
    }
    &__logo-nav-btn {
      margin-right: auto;
      flex: 1;
      padding-right: 0;
    }
    &__nav {
      width: 544px;
      justify-content: flex-start;
    }
    &__nav__item.is-standalone {
      margin-left: auto;
      flex: 1;
      display: flex !important;
      justify-content: flex-end;
    }
  }
}
