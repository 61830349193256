.coupon-purchase {
  @include body-sans-serif-font;
  border-top: 1px solid $black-hairline;
  margin-bottom: 4rem;
  position: relative;
  &__code {
    @include coupon-code-font;
    padding: 0.5rem 1rem;
    margin: 2rem 0 1rem 0;
    display: inline-block;
    &--redeemed {
      background: #e6f6d5;
    }
    &--not-redeemed {
      background: #fff2cd;
    }
  }
  &__status-message {
    display: block;
    color: $gray-3;
    @include body-sans-serif-font;
  }
  &__redemption {
    display: block;
    color: $gray-3;
    margin-bottom: 1rem;
    .coupon-purchase__redemption-dot {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background: $dark-yellow;
      margin-right: 0.25rem;
    }
    &--redeemed {
      .coupon-purchase__redemption-dot {
        background: $green;
      }
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
  }
  &__action-btn {
    @include body-sans-serif-font;
    margin-right: 1rem;
    height: 4rem;
    padding: 0.375rem 1.5rem;
    display: block;
    border: 1px solid lighten($teal, 40%);
    border-radius: 0.5rem;
    color: $teal;
    display: flex;
    flex-direction: row;

    svg {
      position: relative;
      top: 3px;
      margin-right: 6px;
    }

    &--active {
      color: white;
      background: $teal;
    }
  }
  @include breakpoint(480px) {
    &__actions {
      position: absolute;
      top: 2rem;
      right: -1rem;
    }
  }
}
