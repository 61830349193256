.nav-back {
  display: inline-block;
  @include body-sans-serif-font;
  line-height: 4rem;
  color: $teal;
  padding-left: 5rem;
  margin-bottom: 6rem;
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%0A%20%20%3Cg%20fill%3D%22%2319BBD4%22%20transform%3D%22rotate(90%2016%2016)%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M16%2C32%20C24.836556%2C32%2032%2C24.836556%2032%2C16%20C32%2C7.163444%2024.836556%2C0%2016%2C0%20C7.163444%2C0%200%2C7.163444%200%2C16%20C0%2C24.836556%207.163444%2C32%2016%2C32%20Z%20M16%2C31%20C7.71572875%2C31%201%2C24.2842712%201%2C16%20C1%2C7.71572875%207.71572875%2C1%2016%2C1%20C24.2842712%2C1%2031%2C7.71572875%2031%2C16%20C31%2C24.2842712%2024.2842712%2C31%2016%2C31%20Z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M21.2574198%2C16.6666667%20L8%2C16.6666667%20L8%2C15.3333333%20L21.2574198%2C15.3333333%20L16%2C10.3298898%20L16.9793791%2C9.33333333%20L24%2C16%20L16.9793791%2C22.6666667%20L16%2C21.6701102%20L21.2574198%2C16.6666667%20Z%22%20transform%3D%22rotate(90%2016%2016)%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A')
    0 0 no-repeat;
  @include breakpoint(medium) {
    margin-bottom: 8rem;
  }
}
