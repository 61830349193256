.error-boundary {
  @extend %screen-margins-with-gutters;
  padding-top: 6rem + $header-height-small;
  padding-bottom: 10rem;

  &__container {
    max-width: 600px;
    border-radius: 1rem;
    padding: 3rem;
    margin: 0 auto;
    background: $off-white;
  }

  &__heading {
    @include h1-font;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 3rem;
    padding-right: 2rem;
  }
  &__subheading {
    @include h3-font;
    margin: 2rem 0;
  }
  &__message {
    @include body-sans-serif-font;
    padding: 2.5rem 0;
    border-top: 1px solid $black-hairline;
    margin-bottom: 2rem;
  }
}
