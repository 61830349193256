.icon-btn {
  display: block;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 0 1.25rem;
  height: 6rem;
  @include body-sans-serif-font;
  line-height: 6rem;
  color: $text-color;
  text-align: center;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.15s;
  &__icon {
    position: absolute;
    top: 1rem;
  }
  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
}
