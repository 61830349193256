.edu-banner {
  background: $dark-soft-teal;
  text-align: center;
  padding: 2.75rem 0 3.5rem;
  &__icon {
    margin: 0 auto 1.25rem;
    path {
      fill: #fff;
    }
  }
  * {
    color: #fff;
  }
  a {
    @include link(#fff);
  }
  &__text {
    @extend %gutters;
    @include h4-font;
  }
  @include breakpoint(medium) {
    padding: 2.75rem 0;
    .section-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__icon {
      margin: 0;
    }
    &__text {
      padding: 0 0 0 1.25rem;
    }
  }
}
