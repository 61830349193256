.gift-coupons {
  padding-top: 6rem + $header-height-small;
  &__section {
    position: relative;
    &--coupon-cards {
      // pull shadow in front of following section
      z-index: 1;
      padding-bottom: 2rem;
    }
    &--help {
      background: $off-white;
      padding-bottom: 10rem;
    }
  }
  &__wave-bg {
    @include wave($off-white);
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    bottom: 0;
    background: $off-white;
  }
  &__content {
    padding: 0 16px;
  }
  &__purchased {
    margin-bottom: 6rem;
  }
  &__heading {
    @include h3-font;
    margin-bottom: 2rem;
  }
  &__purchase-prompt {
    @include body-sans-serif-font;
  }
  &__coupon {
    position: relative;
    padding-bottom: 3rem;
    &__footnote {
      @include small-text-font;
      color: $gray-3;
      max-width: 300px;
      text-align: center;
      padding-top: 1.5rem;
      margin: 0 auto;
    }
  }
  &__list {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  @include breakpoint(550px) {
    &__content {
      max-width: 576px;
      margin: 0 auto;
    }
  }
  @include breakpoint(650px) {
    &__coupon-list {
      display: flex;
    }
    &__coupon:nth-child(even) {
      margin-left: 1rem;
    }
    &__coupon:nth-child(odd) {
      margin-right: 1rem;
    }
  }
  @include breakpoint(medium) {
    padding-top: 10rem + $header-height-medium;
    &__section {
      &--coupon-cards {
        padding-bottom: 4rem;
      }
      &--help {
        padding-bottom: 14rem;
      }
    }
    &__purchased {
      margin-bottom: 10rem;
    }
    &__purchase-prompt {
      margin-bottom: 1rem;
    }
    &__wave-bg {
      top: 15rem;
    }
  }
}
