.promo-code-input {
  display: flex;
  margin: 3rem 0 2rem;
  &__input {
    @extend %textInput;
    @include h4-font;
    margin-bottom: 0;
    border-width: 1px 0 1px 1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &::placeholder {
      color: $gray-2;
    }
    &:focus {
      z-index: 1;
    }
  }
  &__submit {
    flex-shrink: 0;
    border: none;
    @include h4-font;
    width: 12rem;
    padding: 0rem 0;
    background-color: $primary-btn-color;
    color: #fff;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.15s;
    &:hover {
      background-color: darken($primary-btn-color, 3%);
    }
  }
}
