.actions-list {
  &__heading {
    @include body-sans-serif-font;
    color: $gray-3;
    margin-bottom: 1rem;
    .actions-list--white & {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  &__action {
    @include body-sans-serif-font;
    font-weight: 600;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &__link {
      color: $black;
      display: inline-block;
      @include no-link;
      &:hover {
        color: #000;
        path {
          fill: #000;
        }
      }
      .actions-list--white & {
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
      background: none !important;
    }
    &__icon {
      float: left;
      margin-right: 1rem;
      .actions-list--white & path {
        fill: #fff;
      }
    }
  }
}
