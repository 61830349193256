.app-promo {
  background: $dark-teal;
  overflow: hidden;
  * {
    color: #fff;
  }
  p a {
    @include white-link;
  }
  &__content {
    @extend %section-wrap;
  }
  &__coming-soon {
    margin-bottom: 3rem;
  }
  &__text {
    @extend %gutters;
    padding-top: 5rem;
    padding-bottom: 7rem;
  }
  &__heading {
    @include h2-font;
    margin-bottom: 2rem;
  }
  &__img {
    display: none;
  }
  .btn {
    margin: 3rem 0 0;
  }
  .actions-list {
    margin-top: 6rem;
  }
  &__secondary-download {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 4rem;
    &__heading {
      @include body-font;
      margin: 1rem 1rem 1rem 0;
    }
    .download-btns {
      margin: 0;
    }
  }
  .stars {
    margin-top: 3.5rem;
  }
  @include breakpoint(medium) {
    &__content {
      display: flex;
      justify-content: space-between;
    }
    &__text {
      padding-top: 7rem;
    }
    &__img {
      display: block;
      margin: 7rem $gutter-half-width 0 3rem;
      align-self: flex-end;
      transform: translateY(var(--appPromoParallax));
      will-change: transform;
    }
  }
  @include breakpoint(large) {
    &__text {
      width: 50%;
      max-width: 600px;
    }
    &__img {
      justify-self: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
