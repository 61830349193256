.text {
  @extend %text-column;
  @extend %gutters;
  &--centered {
    @include breakpoint(large) {
      width: percentage(8/12);
      margin: 0 auto;
    }
    @include breakpoint(huge) {
      width: 50%;
    }
  }
}
