.spinner {
  display: none;
  &--white circle {
    stroke: #fff;
  }
  .loading & {
    display: block;
  }
  .message & {
    height: 3rem;
    width: 3rem;
    margin: 1.5rem auto 0;
  }
  .btn &,
  .icon-btn &,
  .btn-wrap & {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
