.blockquote {
  @extend %text-column;
  @extend %gutters;
  margin-top: 6rem;
  margin-bottom: 6rem;
  &__icon {
    float: left;
    margin: 1rem 0 0;
    path {
      fill: $blockquote-color;
    }
  }
  &__text {
    font-style: italic;
    margin-left: 7rem;
  }
  &__source {
    margin: 1rem 0 0 7rem;
  }
  &__text,
  &__source {
    max-width: 500px;
  }
  @include breakpoint(medium) {
    &__icon {
      width: 8rem;
      height: 6.25rem;
    }
    &__text,
    &__source {
      margin-left: 11rem;
    }
  }
}
