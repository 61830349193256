.newsletter {
  padding-top: 5rem;
  background: $footer-bg-color;
  @include wave($footer-bg-color);
  z-index: 1; // pull over wave of footer
  * {
    color: #fff;
  }
  &__content {
    @extend %section-wrap;
    &:after {
      content: '';
      display: block;
      margin: 5rem $gutter-half-width 0;
      border-bottom: 1px solid $white-hairline;
    }
  }
  &__heading {
    @extend %gutters;
    @include h2-font;
    text-align: center;
    margin: 0 auto 3rem;
    max-width: 544px;
  }
  &__text {
    @extend %gutters;
    text-align: center;
    margin: 0 auto 5rem;
    max-width: 600px;
  }
  &__form {
    @extend %gutters;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__label {
      display: none;
    }
    &__input {
      border: none;
      @include h4-font;
      width: calc(100% - 15rem);
      padding: 2rem;
      float: left;
      background: darken($footer-bg-color, 4%);
      border-radius: 4px 0 0 4px;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
    &__submit {
      border: none;
      @include h4-font;
      width: 15rem;
      padding: 2rem 0;
      background-color: $primary-btn-color;
      border-radius: 0 4px 4px 0;
      cursor: pointer;
      transition: background-color 0.15s;
      &:hover {
        background-color: darken($primary-btn-color, 3%);
      }
    }
    &__responses {
      width: 100%;
    }
    &__response {
      @include body-sans-serif-font;
      margin-top: 2rem;
      text-align: center;
      a {
        @include white-link;
      }
    }
  }
  @include breakpoint(smallish) {
    &__text {
      width: percentage(10/12);
    }
    &__form {
      width: percentage(10/12);
      margin-left: auto;
      margin-right: auto;
      &__input {
        border-radius: 4px;
        width: calc(100% - 19rem);
      }
      &__submit {
        width: 17rem;
        border-radius: 4px;
      }
    }
  }
  @include breakpoint(medium) {
    padding-top: 10rem;
    &__content {
      &:after {
        margin-top: 10rem;
      }
    }
    &__heading,
    &__text,
    &__form {
      width: percentage(8/12);
    }
    &__form {
      &__input,
      &__submit {
        padding: 2.5rem 3rem;
      }
    }
  }
  @include breakpoint(large) {
    &__heading,
    &__text,
    &__form {
      width: percentage(6/12);
    }
  }
}
