.people {
  margin-bottom: -6rem;
  &__heading {
    @extend %gutters;
    @include h2-font;
    margin-bottom: 5rem;
  }
  &__person {
    margin-bottom: 6rem;
    &__img-wrap {
      padding: 0 0 0 $gutter-half-width;
    }
    &__img {
      width: 16rem;
      height: 16rem;
      border-radius: 50%;
      margin-bottom: 2rem;
    }
    &__text {
      @extend %gutters;
    }
    &__name {
      @include h4-font;
    }
    &__position {
      @include body-sans-serif-font;
      margin-bottom: 2rem;
    }
    p {
      @include body-sans-serif-font;
    }
  }
  @include breakpoint(600px) {
    &__person {
      display: flex;
      &__img-wrap {
        width: 18rem;
        flex-shrink: 0;
      }
      &__img {
        width: 100%;
        height: auto;
      }
      &__text {
        max-width: 600px;
      }
    }
  }
  @include breakpoint(medium) {
    &__person {
      &__img-wrap {
        padding: 0 $gutter-half-width;
        width: 20rem;
      }
    }
  }
  @include breakpoint(large) {
    display: flex;
    flex-wrap: wrap;
    &__heading {
      margin-bottom: 6rem;
      width: 100%;
    }
    &__person {
      width: 50%;
      margin-bottom: 7rem;
      &__img-wrap {
        width: percentage(1/3);
      }
      &__text {
        width: percentage(2/3);
      }
    }
  }
}
