.auth {
  @extend %screen-margins-with-gutters;
  padding-top: 6rem + $header-height-small;
  padding-bottom: 14rem;
  background: $off-white;
  height: 100%;
  &__tabs {
    display: flex;
    justify-content: center;
    margin: 2rem 0 8rem;
    &__tab {
      @include h3-font;
      margin: 0 2rem;
      color: $gray-3;
      transition: color 0.2s;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -2rem;
        left: 0;
        right: 0;
        height: 1.25rem;
        background: rgba(0, 0, 0, 0);
        border-radius: 3px;
        transition: background-color 0.2s;
      }
      a:hover & {
        color: $black;
        &:after {
          background: rgba(0, 0, 0, 0.1);
        }
      }
      &--selected {
        color: $black;
        position: relative;
        &:after {
          background: $teal;
        }
      }
    }
  }
  &__header {
    position: relative;
  }
  &__heading {
    @include h3-font;
    text-align: center;
    margin: 2rem 0 6rem;
    padding: 0 5rem; // bit of space for back button
    &--large {
      @include h1-font;
      margin: 0 0 5rem;
    }
  }
  &__back-btn {
    position: absolute;
    top: 0;
    left: 0;
    &__text {
      display: none;
    }
    &:hover path {
      fill: darken($dark-teal, 8%);
    }
  }
  &__help {
    @include body-sans-serif-font;
    color: $gray-3;
    margin-bottom: 3rem;
    text-align: center;
    a {
      @include link();
    }
  }
  &__footnote {
    @include body-sans-serif-font;
    text-align: center;
    margin-top: 5rem;
    color: $gray-3;
    a {
      @include link();
    }
  }
  &__small-print {
    @include small-text-font;
    color: $gray-3;
    margin-top: 10rem;
    text-align: center;
    a {
      @include link($gray-3);
    }
  }
  .form {
    padding: 0;
  }
  .btn {
    width: 100%;
  }
  @include breakpoint(440px) {
    &__content {
      width: 50rem;
      margin: 0 auto;
    }
  }
  @include breakpoint(medium) {
    padding-top: 10rem + $header-height-medium;
    padding-bottom: 15rem;
  }
}
