.stars {
  display: flex;
  flex-wrap: wrap;
  &__set {
    display: flex;
    margin-bottom: 2rem;
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
  &__stars {
    font: 18px arial;
    margin: 0 1rem;
    position: relative;
    &__track {
      opacity: 0.5;
    }
    &__white {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
  }
  &__number {
    @include h4-font;
    position: relative;
    top: 0.15rem;
  }
}
