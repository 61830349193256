.article-card {
  @extend %gutters;
  margin-bottom: 5rem;
  &__img {
    margin-bottom: 2rem;
  }
  &__heading {
    @include h3-font;
    margin-bottom: 1rem;
    color: $black;
  }
  &__meta {
    @include body-sans-serif-font;
    color: $gray-3;
  }
  @include breakpoint(650px) {
    width: 50%;
  }
  @include breakpoint(1000px) {
    width: percentage(1/3);
  }
}
