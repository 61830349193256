.aside {
  @extend %section-margins;
  background: $off-white;
  &__content {
    @extend %section-wrap;
  }
  &__heading {
    @extend %gutters;
    @include h2-font;
    margin-bottom: 3rem;
    max-width: $text-max-width;
  }
  &__body {
    @extend %gutters;
    max-width: $text-max-width;
  }
  @include breakpoint(large) {
    &__content {
      display: flex;
      justify-content: space-between;
    }
    &__heading {
      width: percentage(5/12);
    }
    &__body {
      width: percentage(6/12);
    }
  }
  @include breakpoint(huge) {
    &__heading {
      width: percentage(4/12);
    }
  }
}
