html {
  font-size: 8px;
  -webkit-text-size-adjust: none; // prevent iOS from resizing small text
  color: $black;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/******************************************************************************************
WEBFONTS
******************************************************************************************/

/******************************************************************************************
FONT MIXINS
******************************************************************************************/

@mixin body-font {
  // 22px/32px
  font-family: freight-text-pro, serif;
  font-size: 2.75rem;
  line-height: 4rem;
}
@mixin h1-font {
  font-family: larsseit-bold;
  // 40px/44px
  font-size: 5rem;
  line-height: 5.5rem;
  // 50px/56px
  @include breakpoint(medium) {
    font-size: 6.25rem;
    line-height: 7rem;
  }
}
@mixin h2-font {
  // 32px/40px
  font-family: larsseit-bold;
  font-size: 4rem;
  line-height: 5rem;
  @include breakpoint(medium) {
    // 37px/48px
    font-size: 4.625rem;
    line-height: 6rem;
  }
}
@mixin h3-font {
  // 23px/28px
  font-family: larsseit-bold;
  font-size: 2.875rem;
  line-height: 3.5rem;
}
@mixin h4-font-medium {
  // 27px/32px
  font-family: larsseit-medium;
  font-size: 3.375rem;
  line-height: 4rem;
}
@mixin h4-font {
  // 20px/24px
  font-family: larsseit-medium;
  font-size: 2.5rem;
  line-height: 3rem;
}
@mixin hero-tagline {
  // 46px/48px
  font-family: freight-text-pro, serif;
  font-size: 5.75rem;
  line-height: 6rem;
}
@mixin body-sans-serif-font {
  // 17px/24px
  font-family: roboto, sans-serif;
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
}
@mixin small-text-font {
  // 14px/20px
  font-family: roboto, sans-serif;
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: 400;
}
@mixin pricing-card-price-font {
  font-family: larsseit-bold;
  // 40px/44px
  font-size: 5rem;
  line-height: 5.5rem;
}
@mixin testimonial-font {
  // 23px/28px
  font-family: freight-text-pro, serif;
  font-size: 2.875rem;
  font-style: italic;
  line-height: 3.5rem;
  @include breakpoint(smallish) {
    font-size: 3.5rem;
    line-height: 4.25rem;
  }
}
@mixin standfirst-font {
  // 22px/32px
  font-family: freight-text-pro, serif;
  font-size: 2.75rem;
  line-height: 4rem;
  // 32px/40px
  @include breakpoint(medium) {
    font-size: 4rem;
    line-height: 5.5rem;
  }
}
@mixin download-btn-font {
  // 18px/24px
  font-family: roboto, sans-serif;
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 600;
}
@mixin coupon-code-font {
  // 24px/24px
  font-family: 'Roboto Mono', monospace;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 700;
}

@mixin link($link-color: $link-color) {
  color: $link-color;
  background-image: linear-gradient($link-color 50%, rgba(0, 0, 0, 0) 50%);
  background-repeat: repeat-x;
  background-size: 2px 2px;
  background-position: 0 1.12em;
  cursor: pointer;
}
@mixin white-link {
  color: #fff;
  background-image: linear-gradient(#fff 50%, rgba(0, 0, 0, 0) 50%);
  background-repeat: repeat-x;
  background-size: 2px 2px;
  background-position: 0 1.12em;
}
@mixin no-link {
  background: none;
}

/******************************************************************************************
TEXT STYLES
******************************************************************************************/

p,
li {
  @include body-font;
  color: $text-color;
}
ul,
ol {
  @include body-font;
  color: $text-color;
  margin-bottom: 0;
}
li {
  margin-bottom: 0;
  list-style: none;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
a {
  text-decoration: none;
}
a:focus,
a:active,
button:focus,
button:active {
  outline: none;
}
img {
  max-width: 100%;
  display: block;
}
svg {
  display: block;
}
sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.2em;
  font-size: 0.6em;
  line-height: 1em;
}
sub {
  top: 0.2em;
}
// our reset doesn't cover these!
figure,
figcaption {
  margin: 0;
  padding: 0;
}

/******************************************************************************************
WYSIWYG STYLES
******************************************************************************************/

.wysiwyg {
  p {
    margin-bottom: 3rem;
  }
  h2 {
    @include h3-font;
    margin: 5rem 0 3rem;
    @include breakpoint(medium) {
      margin-top: 8rem;
    }
  }
  h3 {
    @include h4-font;
    margin: 4rem 0 3rem;
  }
  ul {
    margin: 0 0 3rem 0;
    li {
      padding-left: 3rem;
      position: relative;
      margin-bottom: 3rem;
      &:before {
        content: '';
        position: absolute;
        top: 1.5rem;
        left: 0;
        width: 1.5rem;
        height: 1.5rem;
        background: $ul-bullet-color;
        border-radius: 50%;
      }
    }
  }
  ol {
    margin-bottom: 3rem;
    counter-reset: ol-counter;
    li {
      counter-increment: ol-counter;
      padding-left: 6rem;
      position: relative;
      margin-bottom: 3rem;
      &:before {
        content: counter(ol-counter);
        @include h3-font;
        color: $link-color;
        float: left;
        margin-left: -6rem;
        margin-top: 1px;
        width: 4rem;
      }
      @include breakpoint(large) {
        padding-left: 0;
        &:before {
          text-align: right;
        }
      }
    }
  }
  &--feature-ols ol li {
    padding-left: 8rem;
    min-height: 8rem;
    &:before {
      width: 6rem;
      height: 6rem;
      border: 1px solid $link-color;
      border-radius: 50%;
      text-align: center;
      font-size: 4.75rem;
      line-height: 6rem;
      margin: 1rem 0 0 -8rem;
    }
    @include breakpoint(large) {
      padding-left: 0;
    }
  }
  table {
    border-top: 1px solid $gray-1;
    margin: 4rem 0;
    td,
    td p {
      @include body-sans-serif-font;
      vertical-align: top;
    }
    td {
      display: block;
      padding: 1.5rem 0;
      @include breakpoint(smallish) {
        display: table-cell;
        min-width: 100px;
        &:not(:last-child) {
          padding-right: 3rem;
        }
      }
    }
    tr {
      border-bottom: 1px solid $gray-1;
    }
  }

  a {
    @include link;
  }
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
  &--sans-serif {
    p,
    li {
      @include body-sans-serif-font;
    }
  }
}

/******************************************************************************************
SMALL-PRINT STYLES
******************************************************************************************/

.small-print {
  p {
    margin-bottom: 3rem;
    @include body-sans-serif-font;
  }
  h2 {
    @include h4-font;
    margin: 3rem 0;
  }
  h3 {
    margin: 3rem 0;
    @include body-sans-serif-font;
    font-weight: 600;
  }
  strong,
  bold {
    font-weight: 600;
  }
  ul {
    margin: 0 0 3rem 0;
    li {
      @include body-sans-serif-font;
      padding-left: 2.5rem;
      position: relative;
      margin-bottom: 3rem;
      &:before {
        content: '';
        position: absolute;
        top: 1rem;
        left: 0;
        width: 1rem;
        height: 1rem;
        background: $text-color;
        border-radius: 50%;
      }
    }
  }

  ol {
    margin-bottom: 3rem;
    counter-reset: ol-counter;
    li {
      @include body-sans-serif-font;
      counter-increment: ol-counter;
      padding-left: 4rem;
      position: relative;
      margin-bottom: 3rem;
      &:before {
        content: counter(ol-counter);
        @include body-sans-serif-font;
        font-weight: 600;
        float: left;
        margin-left: -4rem;
        width: 4rem;
      }
    }

    ol {
      margin: 3rem 0 !important;
      li {
        counter-increment: list;
        padding-left: 3rem;
        margin: 0;
        &:before {
          content: counter(list, lower-alpha) '. ';
          @include body-sans-serif-font;
          width: 3rem;
          margin-left: -3rem;
          margin-top: 0;
          color: $text-color;
        }
      }
      ol {
        margin: 0 !important;
        li {
          counter-increment: sublist;
        }

        li:before {
          content: counter(sublist, lower-roman) '. ';
        }
      }
    }
  }
  a {
    @include link;
  }

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
}

/******************************************************************************************
FORMS
******************************************************************************************/

input,
textarea,
select,
button {
  -webkit-appearance: none;
  border-radius: 0;
}
.input,
.textarea,
.btn,
.select {
  border: none;
}
.select {
  display: inline-block;
  // remove select arrows
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ''; // removes arrow from FF - see http://css-tricks.com/almanac/properties/a/appearance/
  // add our own arrow
  &:after {
    content: '';
    display: inline-block;
    width: 0;
    border-style: solid;
    border-width: 1rem 0.6rem 0;
    border-color: #000 transparent transparent;
    margin-left: 0.9rem;
    position: relative;
    top: 0.05rem;
  }
}
// remove select arrows on IE
select::-ms-expand {
  display: none;
}
