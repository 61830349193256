.holding-page {
  height: 100%;
  background: $dark-teal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__logo {
    width: 20rem;
    height: 20rem;
    path {
      fill: #fff;
    }
  }
  &__p {
    @include h4-font;
    color: lighten($dark-teal, 35%);
    a {
      @include link(lighten($dark-teal, 35%));
      &:hover {
        color: #fff;
      }
    }
  }
}
