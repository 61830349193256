.press-header {
  padding-top: 6rem + $header-height-small;
  padding-bottom: 3rem;
  background: $dark-blue;
  * {
    color: #fff;
  }
  p a {
    @include white-link;
  }
  &__heading {
    @extend %gutters;
    @include h1-font;
    margin-bottom: 4rem;
  }
  &__section {
    @extend %gutters;
    margin-bottom: 6rem;
    &__heading {
      @include h2-font;
      margin-bottom: 2rem;
    }
    .btn {
      margin-top: 3rem;
    }
  }
  @include breakpoint(medium) {
    padding-top: 10rem + $header-height-medium;
    padding-bottom: 6rem;
    &__heading {
      margin-bottom: 10rem;
    }
    &__sections {
      display: flex;
      flex-wrap: wrap;
    }
    &__section {
      width: 50%;
      &:last-child {
        width: 100%;
      }
    }
  }
  @include breakpoint(large) {
    &__section,
    &__section:last-child {
      width: percentage(1/3);
    }
  }
}
