.flickity-page-dots {
  position: relative;
  bottom: 0;
  line-height: 1px;
  margin-top: 2rem;
  .dot {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.75rem;
    background-color: $gray-2;
    opacity: 1;
    &.is-selected {
      background-color: $teal;
    }
  }
}
