#footer {
  position: relative;
}
.version {
  @include body-sans-serif-font;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
}
.site-footer {
  background: $footer-bg-color;
  padding-top: 4rem;
  * {
    color: #fff;
  }

  &__content {
    @extend %section-wrap;
  }

  &__links {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &__set {
      @extend %gutters;
      width: 50%;
      margin-bottom: 3rem;
    }

    &__heading {
      @include body-sans-serif-font;
      font-weight: 400;
      margin-bottom: 1rem;
    }

    &__list {
      margin-bottom: 1rem;
    }

    &__item {
      @include body-sans-serif-font;
      margin-bottom: 1rem;
    }

    &__link {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

    &__icon {
      display: inline-block;
      margin-right: 0.5rem;
      position: relative;
      top: 0.2rem;
    }
  }

  &__legal {
    @extend %gutters;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    justify-content: center;
    &:after {
      content: '';
      width: 100%;
      padding-top: 2rem;
      border-bottom: 1px solid $white-hairline;
    }

    &__item {
      @include small-text-font;

      &:not(:last-child):after {
        content: '';
        display: inline-block;
        margin: 0 1rem;
        height: 1.5rem;
        position: relative;
        top: 0.2rem;
        border-right: 1px solid $white-hairline;
      }
    }

    &__link {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__logos {
    padding: 3rem 0;

    &__logo {
      width: 20.5rem;
      height: 5rem;
      margin: 0 auto;
    }
  }

  @include breakpoint(540px) {
    &__logos {
      padding: 2rem 0;
      display: flex;
      justify-content: center;

      &__logo {
        margin: 0 $gutter-half-width;
      }
    }
  }

  @include breakpoint(medium) {
    padding-top: 3.5rem;
    &__links {
      display: flex;
      justify-content: center;
      &__set {
        width: auto;
        padding: 0;
        margin: 0 0 4rem 0;
      }
      &__list {
        display: flex;
        justify-content: flex-end;
        margin: 0;
      }
      &__set:last-child &__list {
        justify-content: flex-start;
      }
      &__heading {
        display: none;
      }
      &__item {
        margin: 0 1.5rem;
      }
    }

    &__legal {
      margin-top: 0;
    }
  }

  @include breakpoint(1020px) {
    padding-bottom: 0;
    &__content {
      position: relative;
    }
    &__links {
      justify-content: space-between;
      &:after {
        content: '';
        width: 100%;
        border-bottom: 1px solid $white-hairline;
        margin: 0 $gutter-half-width;
      }
      &__set {
        margin-bottom: 3.5rem;
        padding: 0 0.5rem;
      }
    }

    &__legal {
      position: absolute;
      bottom: 3.5rem;
      left: $screen-margin-medium;
      margin: 0;

      &:after {
        content: none;
      }
    }

    &__logos {
      &__logo {
        width: auto;
        height: auto;
      }
    }
  }
}
