.image {
  @extend %gutters;
  @extend %text-column;
  margin-top: 5rem;
  margin-bottom: 5rem;
  &__img {
    display: block;
    &:not(.image__img--fixed-size) {
      width: 100%; // Safari doesn't load in larger responsive images when you widen your browser, so this at least makes sure the layout doesn't get messed up
    }
  }
}
