.pricing-card {
  background: $darker-blue;
  border-radius: 4px;
  margin-bottom: 2rem;
  padding: 3rem 2rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  * {
    color: #fff;
    text-align: center;
  }
  &--monthly {
    background: $dark-soft-teal;
  }
  &--yearly {
    background: $soft-green;
  }
  &__special-offer {
    margin: -1rem auto 1rem;
  }
  &__heading {
    @include h3-font;
    margin-bottom: 1rem;
  }
  &__price {
    @include pricing-card-price-font;
    margin-bottom: 2.5rem;
    position: relative;
    &__currency {
      @include h4-font-medium;
      position: relative;
      bottom: 0.9rem;
      margin-right: 0.125rem;
    }
    &__cents {
      @include h4-font-medium;
    }
    &__mo {
      @include h4-font-medium;
      opacity: 0.5;
    }
  }
  &__discount {
    background: $yellow;
    margin: 0 -3rem 3rem 1rem;
    padding: 1rem 3rem 1rem 0;
    position: relative;
    &:before {
      display: block;
      content: '';
      width: 1rem;
      height: 8rem;
      position: absolute;
      top: 0;
      left: -1rem;
      // _src/img/misc/discount-banner-edge.svg
      background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228%22%20height%3D%2264%22%20viewBox%3D%220%200%208%2064%22%3E%3Cpath%20fill%3D%22%23FFDF5E%22%20fill-rule%3D%22evenodd%22%20d%3D%22M0%200h8v64H0l8-30.4z%22%2F%3E%3C%2Fsvg%3E);
    }
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 0;
      bottom: -1rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1rem 1rem 0 0;
      border-color: darken($yellow, 20%) transparent transparent transparent;
    }
    &__type,
    &__original {
      @include body-sans-serif-font;
      color: rgba(0, 0, 0, 0.5) !important;
    }
    &__type {
      font-weight: 600;
    }
  }
  &__primary-feature,
  &__secondary-feature {
    @include body-sans-serif-font;
  }
  &__secondary-feature {
    color: rgba(255, 255, 255, 0.6);
    padding-bottom: 4rem;
    margin-bottom: auto;
  }
  .download-btns {
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .btn {
    width: 100%;
    margin-top: 0 !important;
  }
  &__your-plan {
    @include h4-font;
    margin-bottom: 1.5rem;
    &__icon {
      display: inline-block;
      vertical-align: top;
      margin-right: 0.5rem;
    }
  }
  @include breakpoint(medium) {
    padding-top: 4rem;
    &__secondary-feature {
      padding-bottom: 4.5rem;
    }
  }
  @include breakpoint(480px) {
    width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
  .pricing-card-list--default & {
    @include breakpoint($pricing-card-breakpoint) {
      width: 33rem;
      flex-shrink: 1;
      margin: 0 1rem;
      position: relative;
      &__special-offer {
        position: absolute;
        top: -2rem;
        right: -1.2rem;
      }
    }
  }
  .pricing-card-list--account & {
    @include breakpoint($account-pricing-card-breakpoint) {
      width: auto;
      flex-grow: 1;
      position: relative;
      &:first-child {
        margin-right: 2rem;
      }
      &__special-offer {
        position: absolute;
        top: -2rem;
        right: -1.2rem;
      }
    }
  }
}
