.screenshot-carousel {
  margin: 6rem 0;
  background: transparent center center no-repeat;
  background-size: 324px;
  background-position: top center;
  bag &__img-wrap {
    width: 100%;
  }
  &__img {
    margin: 0 auto;
    padding: 0 2rem;
    width: 324px;
    box-sizing: content-box;
  }
}
