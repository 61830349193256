.jw-cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #222;
  transform: translateY(100%);
  transition: transform 0.3s;
  .show-jw-cookies & {
    transform: translateY(0);
  }
  &__content {
    padding: 1rem $screen-margin-small-with-gutter;
    display: flex;
  }
  &__p {
    color: #fff;
    @include body-sans-serif-font;
    margin-top: 2px;
  }
  &__link {
    @include link(#fff);
  }
  .btn {
    font-weight: 600;
    margin: 0 0 0 2rem;
    background-color: #444;
    &:hover {
      background-color: #555;
    }
  }
  &__thanks {
    display: none;
  }
  @include breakpoint(smallish) {
    &__content {
      justify-content: center;
    }
    &__thanks {
      display: inline-block;
    }
  }
}
