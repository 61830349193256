/******************************************************************************************
BREAKPOINTS
******************************************************************************************/

@mixin breakpoint($point) {
  @if $point == huge {
    @media screen and (min-width: $huge-screen) {
      @content;
    }
  } @else if $point == large {
    @media screen and (min-width: $large-screen) {
      @content;
    }
  } @else if $point == medium {
    @media screen and (min-width: $medium-screen) {
      @content;
    }
  } @else if $point == smallish {
    @media screen and (min-width: $smallish-screen) {
      @content;
    }
  } @else {
    @media screen and (min-width: $point) {
      @content;
    }
  }
}
@mixin below-breakpoint($point) {
  @if $point == huge {
    @media screen and (max-width: $huge-screen - 1) {
      @content;
    }
  } @else if $point == large {
    @media screen and (max-width: $large-screen - 1) {
      @content;
    }
  } @else if $point == medium {
    @media screen and (max-width: $medium-screen - 1) {
      @content;
    }
  } @else if $point == smallish {
    @media screen and (max-width: $smallish-screen - 1) {
      @content;
    }
  } @else {
    @media screen and (max-width: $point - 1) {
      @content;
    }
  }
}

/******************************************************************************************
HELPER MIXINS
******************************************************************************************/

@mixin box {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  *behavior: url(/js/third-party/boxsizing.htc);
}

@mixin clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
  zoom: 1;
}

/******************************************************************************************
100vh FOR iOS (SETS HEIGHT EXPLICITLY FOR IOS, AS VH IS BUGGY IN SAFARI)
******************************************************************************************/

// these are whatever is the deepest chrome for each device in Safari or Chrome
$iphone-chrome-height-portait: 108px;
$iphone-chrome-height-landscape: 43px;
$ipad-chrome-height-portait: 116px;
$ipad-chrome-height-landscape: 116px;

@mixin vh100-ios($selector: 'height') {
  #{$selector}: 100vh;
  #{$selector}: calc(var(--vh, 1vh) * 100);
}

@mixin wave($color: 'fff') {
  position: relative;
  &:before {
    content: '';
    display: block;
    height: 2rem;
    position: absolute;
    top: -2rem;
    left: 0;
    right: 0;
    background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22480%22%20height%3D%2216%22%20viewBox%3D%220%200%20480%2016%22%3E%0A%20%20%3Cpath%20fill%3D%22#{$color}%22%20fill-rule%3D%22evenodd%22%20d%3D%22M-1.70530257e-13%2C8%20C30%2C12%2060%2C16%20120%2C16%20C240%2C16%20240%2C0%20360%2C0%20C420%2C0%20450%2C4%20480%2C8%20L480%2C16%20L-1.70530257e-13%2C16%20L-1.70530257e-13%2C8%20Z%22%2F%3E%0A%3C%2Fsvg%3E%0A)
      center center;
    @include breakpoint(610px) {
      background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22480%22%20height%3D%2216%22%20viewBox%3D%220%200%20480%2016%22%3E%3Cpath%20fill%3D%22#{$color}%22%20fill-rule%3D%22evenodd%22%20d%3D%22M0%2016C120%2016%20120%200%20240%200s120%2016%20240%2016%22%2F%3E%3C%2Fsvg%3E)
        center center;
    }
  }
}
