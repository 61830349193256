%medium-heading {
  font-family: larsseit-bold;
  font-weight: 'normal';
  font-size: 23px;
  letter-spacing: 0;
  line-height: 28px;
}

%website--nav {
  font-family: larsseit-medium;
  font-weight: 'normal';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

%body {
  font-family: roboto;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 24px;
}

%stat {
  font-family: larsseit-bold;
  font-weight: 'normal';
  font-size: 29px;
  letter-spacing: 0;
  line-height: 32px;
}

%script--script {
  font-family: sourceserifpro-regular;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 36px;
}

%script--notes {
  font-family: sourceserifpro-regular;
  font-weight: 400;
  font-size: 19px;
  letter-spacing: -0.20000000298023224;
  line-height: 28px;
}

%code {
  font-family: Monaco;
  font-postscriptname: 'Monaco';
  font-weight: 0;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

%script--chapter-heading {
  font-family: SourceSerifPro-Regular;
  font-weight: 0;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
}

%nav {
  font-family: roboto;
  font-weight: 0;
  font-size: 19px;
  letter-spacing: 0;
  line-height: 24px;
}

%script--hint {
  font-family: sourceserifpro-it;
  font-weight: 0;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

%body-bold {
  font-family: 'roboto-Medium';
  font-weight: 0;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 24px;
}

%small-text {
  font-family: roboto;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

%small-text-bold {
  font-family: 'roboto-Medium';
  font-weight: 0;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

%tiny-text {
  font-family: roboto;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}

%small-heading {
  font-family: roboto-Bold;
  font-weight: 0;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 24px;
}

%large-heading--mobile {
  font-family: larsseit-bold;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 36px;
}

%large-heading--desktop {
  font-family: larsseit-bold;
  font-weight: 700;
  font-size: 37px;
  letter-spacing: 0;
  line-height: 48px;
}

.text-style__medium-heading {
  @extend %medium-heading;
}
.text-style__website--nav {
  @extend %website--nav;
}
.text-style__body {
  @extend %body;
}
.text-style__stat {
  @extend %stat;
}
.text-style__script--script {
  @extend %script--script;
}
.text-style__script--notes {
  @extend %script--notes;
}
.text-style__code {
  @extend %code;
}
.text-style__script--chapter-heading {
  @extend %script--chapter-heading;
}
.text-style__nav {
  @extend %nav;
}
.text-style__script--hint {
  @extend %script--hint;
}
.text-style__body-bold {
  @extend %body-bold;
}
.text-style__small-text {
  @extend %small-text;
}
.text-style__small-text-bold {
  @extend %small-text-bold;
}
.text-style__tiny-text {
  @extend %tiny-text;
}
.text-style__small-heading {
  @extend %small-heading;
}
.text-style__large-heading--mobile {
  @extend %large-heading--mobile;
}
.text-style__large-heading--desktop {
  @extend %large-heading--desktop;
}
