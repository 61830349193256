.coupon-promo {
  @extend %gutters;
  text-align: center;
  max-width: 570px;
  margin: 0 auto;
  * {
    color: #fff;
  }
  &__icon {
    margin: 0 auto 3rem;
  }
  .wysiwyg {
    h2 {
      margin-top: 0;
    }
    a {
      @include link(#fff);
    }
  }
  .btn {
    margin-top: 3rem;
  }
}
