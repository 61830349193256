.pricing {
  overflow: hidden;
  position: relative;
  padding: 6rem + $header-height-small 0 8rem;
  background: $nav-bg-color;
  &__bg-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover !important;
    transform: translateY(var(--heroParallax));
    will-change: transform;
  }
  &__content {
    @extend %section-wrap;
    position: relative;
  }
  &__heading {
    @extend %gutters;
    @include h1-font;
    color: #fff;
    margin-bottom: 8rem;
    text-align: center;
  }
  &__footnote {
    @extend %gutters;
    @include body-sans-serif-font;
    color: #fff;
    text-align: center;
    margin: 5rem 0 0;
    a {
      @include link(#fff);
    }
  }
  @include breakpoint(medium) {
    padding: 10rem + $header-height-medium 0 13rem;
    &__heading {
      margin-bottom: 10rem;
    }
  }
}
