.icon-link-list {
  &__item {
    margin-bottom: 2rem;
  }
  &__icon {
    float: left;
    margin-right: 1.5rem;
    path {
      fill: $teal;
    }
  }
}
