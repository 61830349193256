/******************************************************************************************
JIVEWORLD THEME
******************************************************************************************/

$black: #444444;
$gray-3: #999999;
$gray-2: #d0d0d0;
$gray-1: #e5e5e5;
$off-white: #fafafa;
$off-off-white: #f0f0f0;
$dark-blue: #4c7faa;
$darker-blue: #4c7faa;
$teal: #19bbd4;
$dark-teal: #37aabc;
$darker-teal: #0a91a6;
$soft-teal: #6bbdca;
$dark-soft-teal: #4daebd;
$green: #69c00d;
$soft-green: #18b589;
$dark-soft-green: #009b6f;
$yellow: #ffdf5e;
$dark-yellow: #f7c70a;
$light-yellow: #ffeeaa;
$orange: #f5832b;
$red: #e55c58;
$black-hairline: rgba(0, 0, 0, 0.12);
$white-hairline: rgba(255, 255, 255, 0.3);

$text-color: $black;
$link-color: $teal;
$error-color: $red;

$nav-bg-color: #395f83;
$footer-bg-color: $dark-blue;
$primary-btn-color: $teal;
$home-hero-bg-color: $darker-blue;
$blockquote-color: $yellow;
$ul-bullet-color: $teal;
