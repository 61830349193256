.testimonial {
  @extend %section-margins;
  background: $off-white;
  &__content {
    display: flex;
  }
  &__text {
    @extend %gutters;
  }
  &__icon {
    margin: 1rem 0 2rem;
  }
  &__quote {
    @include testimonial-font;
  }
  &__source {
    @include body-font;
    display: block;
    margin-top: 1rem;
  }
  &__img {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    margin: 7.5rem $gutter-half-width 0 1rem;
    flex-shrink: 0;
  }
  @include breakpoint(smallish) {
    &__img {
      height: 14rem;
      width: 14rem;
    }
  }
  @include breakpoint(medium) {
    &__content {
      width: percentage(10/12);
      margin: 0 auto;
    }
    &__icon {
      float: left;
      margin: 1.25rem 0 0 -7rem;
    }
    &__img {
      margin: 1rem $gutter-half-width 0 2rem;
      width: 15rem;
      height: 15rem;
    }
  }
  @include breakpoint(large) {
    &__content {
      width: percentage(8/12);
      align-items: center;
    }
    &__img {
      align-self: flex-start;
      margin-left: 3rem;
      width: calc(25% - #{$gutter-half-width * 2});
      height: calc(25% - #{$gutter-half-width * 2});
    }
  }
}
