.section {
  @extend %section-margins;
  &--off-white {
    background: $off-white;
  }
  &--off-off-white {
    background: $off-off-white;
  }
  &--dark-blue {
    background: $dark-blue;
  }
  &--white-wave {
    @include wave('#fff');
  }
  &--h100 {
    height: 100%;
  }
}
