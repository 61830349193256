.error-404 {
  height: 100%;
  background: $off-white;
  padding-top: 6rem + $header-height-small;
  padding-bottom: 8rem;
  &__content {
    @extend %section-wrap;
  }
  &__heading {
    @extend %gutters;
    @include h2-font;
    color: $error-color;
    margin-bottom: 5rem;
  }
  &__body {
    @extend %gutters;
    @include h3-font;
    color: $gray-3;
  }
  @include breakpoint(smallish) {
    padding-bottom: 10rem;
    &__heading,
    &__body {
      text-align: center;
    }
  }
  @include breakpoint(medium) {
    padding-top: 10rem + $header-height-medium;
    padding-bottom: 14rem;
    &__content {
      padding: 0;
      max-width: 626px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
