body.site-alert {
  padding-top: 9rem;
  .site-header {
    position: absolute;
    top: 9rem !important;
    margin: 0;
    transform: none;
  }
}
.site-alert-banner {
  background: $yellow;
  padding: 1rem 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8rem; // fix IE flex bug https://github.com/philipwalton/flexbugs/issues/231
  min-height: 9rem;
  text-align: center;
  display: flex;
  align-items: center;
  z-index: 9999;
  &__p {
    @extend %gutters;
    @include body-sans-serif-font;
    a {
      color: darken($dark-yellow, 15%);
      text-decoration: underline;
    }
  }
  &__icon {
    display: inline-block;
    height: 3rem;
    width: 3rem;
    vertical-align: top;
    margin: 0 0.5rem 0 0;
    path {
      fill: $text-color;
    }
  }
}
