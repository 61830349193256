.btn {
  -webkit-appearance: none;
  cursor: pointer;
  @include h4-font;
  text-align: center;
  display: inline-block;
  background: $primary-btn-color;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  color: #fff;
  white-space: nowrap;
  &:hover {
    background: darken($primary-btn-color, 4%);
  }
  &--darker-teal {
    background: $darker-teal;
    &:hover {
      background: darken($darker-teal, 3%);
    }
  }
  &--dark-soft-green {
    background: $dark-soft-green;
    &:hover {
      background: darken($dark-soft-green, 3%);
    }
  }
  &--black {
    background: $black;
    &:hover {
      background: darken($black, 5%);
    }
  }
  &--small {
    @include small-text-font;
    padding: 0.75rem 2rem;
    border-radius: 2rem;
  }
  &--outline {
    background: transparent;
    border: 1px solid $black-hairline;
    color: $text-color;
    transition: border-color 0.1s;
    &.btn--small {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
    }
    &:hover {
      background: transparent;
      border-color: $gray-3;
    }

    &.btn--danger {
      border-color: rgba(229, 92, 88, 0.4);
      color: #e55c58;
    }
  }
  &--round {
    font-size: 2.125rem;
    line-height: 3rem;
    border-radius: 3rem;
    padding: 1rem 3rem;
  }
  &:disabled {
    background: rgba(0, 0, 0, 0.1);
    cursor: default;
  }
  &__icon {
    float: left;
    &--right {
      float: right;
      margin: 0 -0.5rem 0 1rem;
    }
  }
}
