/******************************************************************************************
LAYOUT VARIABLES
******************************************************************************************/

// SCREEN SIZES

$max-screen-width: 1300px;
$smallish-screen: 550px;
$medium-screen: 768px;
$large-screen: 992px;
$huge-screen: 1200px;

$text-max-width: 800px;

$nav-breakpoint: 880px;
$header-height-small: 8rem;
$header-height-medium: 9rem;

// GUTTERS

$gutter-half-width: 16px;
$screen-margin-small: 0;
$screen-margin-medium: 16px;
$screen-margin-small-with-gutter: $screen-margin-small + $gutter-half-width;
$screen-margin-medium-with-gutter: $screen-margin-medium + $gutter-half-width;

// MISC

$pricing-card-width: 33rem;
$pricing-card-breakpoint: 700px;
$account-pricing-card-breakpoint: 550px;
